import moment from 'moment';
import { rrulestr } from 'rrule'
import CryptoJS from 'crypto-js';
import Pricing from 'miscellaneous/pricing';

export default (function () {

  const publicObj = {

    isMobile: function () {
      return window.innerWidth < 960;
    },

    isWindows: function () {
      return navigator.platform.indexOf("Win") > -1
    },

    get language() {
      return localStorage['language'] || 'he';
    },

    get hideSensitiveData() {
      return localStorage['hideSensitiveData'] || false;
    },

    copyToClipboard2: str => {
      const el = document.createElement('textarea');
      el.value = str;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    },

    copyToClipboard: str => {
      const txt = document.createElement('textarea');
      document.body.appendChild(txt);
      txt.value = str; // chrome uses this
      txt.textContent = str; // FF uses this
      var sel = getSelection();
      var range = document.createRange();
      range.selectNode(txt);
      sel.removeAllRanges();
      sel.addRange(range);
      if (document.execCommand('copy')) {
        console.log('copied');
      }
      document.body.removeChild(txt);
    },

    isInteger(val) {
      return val % 1 === 0;
    },

    serializeRecurrenceVirtualId(event) {
      let {id, start, duration, recurrenceStart, meetingType, title} = event;
      if(event.hasOwnProperty('date'))
        start = event.date;
      return ['recur' , id , moment(start).utc().format() , duration , recurrenceStart , (meetingType?.id || ''), title].join("_");
    },

    deserializeRecurrenceVirtualId(serializedId) {
      const parts = serializedId.split('_');
      var obj = {
        recurrenceId: parts[1],
        start: new Date(parts[2]),
        originalStart: new Date(parts[2]),
        duration: parseInt(parts[3]),
        recurrenceStart: new Date(parts[4])
      }
      if (parts[5]) 
        obj.meetingType = parts[5];
      
      if (parts[6]) 
        obj.title = parts[6];

      return obj;
    },

    saveToSessionStorage(keyName, keyValue) {
      const secretKey = 'therapist@123secret';
      const encryptedData = CryptoJS.AES.encrypt(
        JSON.stringify(keyValue),
        secretKey
      ).toString();
      sessionStorage.setItem(keyName, encryptedData);
    },

    getSessionStorageData(keyName) {
      const secretKey = 'therapist@123secret';
      const dataFetched = sessionStorage.getItem(keyName);
      if (dataFetched) {
        var bytes = CryptoJS.AES.decrypt(dataFetched, secretKey);
        var decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        return typeof decryptedData == "object"
          ? decryptedData
          : JSON.parse(decryptedData);
      }
      return null;
    },

    removeSessionStorageData(keyName) {
      sessionStorage.removeItem(keyName);
    },

    clearSessionStorage() {
      sessionStorage.clear();
    },

    getAmount(obj) {
      return Pricing.getAmount(obj, true);
      // const amount = obj.amount || obj.price || obj.meetingPrice || 0;
      // switch (obj.currency) {
      //   case 'USD':
      //     return amount * 3.55;
      //   case 'EUR':
      //     return amount * 3.45;
      //   case 'AUD':
      //     return amount * 2.21;
      //   case 'GBP':
      //     return amount * 3.99;
      //   default:
      //     return amount;
      // }
    },

    getCurrencySymbol(c) {
      switch (c) {
        case 'ILS':
          return '₪';
        case 'EUR':
          return '€';
        case 'GBP':
          return '£';
        case 'USD':
          return '$';
        case 'AUD':
          return 'AU$';
        case 'CAD':
          return 'CA$';
        default:
          return '₪';
      }
    },

    // meetingPriceInILS(meetingType) {
    //   switch (meetingType.currency) {
    //     case 'USD':
    //       return meetingType.price * 3.12;
    //     case 'EUR':
    //       return meetingType.price * 3.54;
    //     case 'AUD':
    //       return meetingType.price * 2.24;
    //     case 'GBP':
    //       return meetingType.price * 4.23;
    //     default:
    //       return meetingType.price;
    //   }
    // },

    rruleToText(rrule) {
      const rruleset1 = rrulestr(rrule, {
        forceset: true
      });
      return rruleset1.rrules()[0].toText(publicObj.rrule.gettext, publicObj.rrule.hebrew)
    },

    getCurrencyName(c) {
      switch (c) {
        case 'ILS':
          return 'Shekel';
        case 'USD':
          return 'Dollar';
        case 'EUR':
          return 'Euro';
        case 'GBP':
          return 'GBP';
        case 'AUD':
          return 'Australian Dollar';
        case 'CAD':
          return 'Canadian Dollar';
        default:
          return 'Shekel';
      }
    },

    validateEmail: (email) => {
      return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    }

  }

  publicObj.rrule = (function () {
    var hebrewStrings = {
      'every': 'פעם',
      'until': 'עד',
      'day': 'ביום',
      'days': 'ימים',
      'week': 'בשבוע',
      'weeks': 'שבועות',
      'on': 'על'
      // …
    };

    return {
      hebrew: {
        dayNames: [
          "ראשון",
          "שני",
          "שלישי",
          "רביעי",
          "חמישי",
          "שישי",
          "שבת"
        ],
        monthNames: [
          "ינו׳",
          "פבר׳",
          "מרץ",
          "אפר׳",
          "מאי",
          "יוני",
          "יולי",
          "אוג׳",
          "ספט׳",
          "אוק׳",
          "נוב׳",
          "דצמ׳"
          // …
        ]
        // `tokens` are only needed for `RRule.fromText`

      },
      gettext: function (id) {
        // Return pt. string, default to english.
        return hebrewStrings[id] || id;
      }
    }
  })();


  return publicObj;
}());