import React, { Suspense } from 'react';
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import Amplify from '@aws-amplify/core';
import { addLocaleData, IntlProvider } from 'react-intl';
import locale_he from 'react-intl/locale-data/he';
import moment from 'moment';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import heLocale from 'date-fns/locale/he';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { create } from 'jss';
import rtl from 'jss-rtl';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
/* eslint-disable no-unused-vars */
import axios from './miscellaneous/axios';
import justLoadMomentHe from "moment/locale/he";
import ErrorBoundary from "views/components/ErrorBoundary/ErrorBoundary.jsx";
import Authentication from "api/Authentication";
/* eslint-enable no-unused-vars */


import { StylesProvider, createGenerateClassName, jssPreset } from '@material-ui/core/styles';

/*  local imports  */
import "assets/scss/material-dashboard-pro-react.css?v=1.5.0";
import indexRoutes from "routes/index.jsx";
import * as serviceWorker from './miscellaneous/serviceWorker';
import amplifyConfig from './config/aws-exports';
import i18nConfig from './texts/i18config';
import theme from './miscellaneous/theme';
import general from './miscellaneous/general';


const jss = create({ plugins: [...jssPreset().plugins, general.language === 'he' ? rtl() : null] });
const generateClassName = createGenerateClassName();


if(general.language === 'he')
 addLocaleData([...locale_he]);
const muitheme = createMuiTheme(theme);
moment.locale(general.language)
Amplify.configure(amplifyConfig)
Authentication.init();
const history = createBrowserHistory();

ReactGA.initialize('UA-160373141-1');
ReactPixel.init('1301176896975008', { em: 'some@email.com' }, { autoConfig: true, debug: false });
ReactPixel.pageView();

ReactDOM.render((
  <ThemeProvider theme={muitheme}>
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={general.language === 'he' ? heLocale: null}>
      <StylesProvider jss={jss} generateClassName={generateClassName}>
        <IntlProvider locale={general.language} messages={i18nConfig.messages[general.language]} >
          <ErrorBoundary>
            <Router history={history}>
              <Suspense fallback={<div></div>}>
                <Switch>
                  {indexRoutes.map((prop, key) => {
                    if (prop.redirect)
                      return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
                    return <Route path={prop.path} component={prop.component} key={key} />;
                  })}
                </Switch>
              </Suspense>
            </Router>
          </ErrorBoundary>
        </IntlProvider>
      </StylesProvider>
    </MuiPickersUtilsProvider>
  </ThemeProvider>
), document.getElementById("root"));

history.listen((location) => {
  //console.log('setting pageview: ' + window.location.pathname + window.location.search)

  ReactGA.pageview(window.location.pathname + window.location.search);
  ReactPixel.pageView();
});


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
